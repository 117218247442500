:root {
  --backgroundColor: #FFFFFF;
  --textColor: #000000;
  --linkColor: #000000;
  --linkHoverColor: #000000;
}
a.load {
  --linkColor: #000000;
  --linkHoverColor: #FFFFFF;
}
.section--moodvideo,
.section--black {
  --backgroundColor: #000000;
  --textColor: #FFFFFF;
  --linkColor: #FFFFFF;
  --linkHoverColor: #FFFFFF;
}
.section--moodvideo a.load,
.section--black a.load {
  --linkColor: #FFFFFF;
  --linkHoverColor: #000000;
}
.section--grey {
  --backgroundColor: #F0F0F0;
}
.unit--boxLinked {
  --linkColor: #000000;
  --linkHoverColor: #000000;
}
.unit--boxLinked.unit--hasBackground {
  --linkColor: #FFFFFF;
  --linkHoverColor: #FFFFFF;
}
.unit--unitGameDay {
  --backgroundColor: #000000;
  --textColor: #FFFFFF;
  --linkColor: #FFFFFF;
  --linkHoverColor: #FFFFFF;
}
.unit--unitGame {
  --backgroundColor: #F0F0F0;
}
:root {
  --spaceArea: calc(100px - var(--spacePart) - var(--spaceUnit));
}
@media (max-width: 1279px) {
  :root {
    --spaceArea: calc(100px - var(--spacePart) - var(--spaceUnit));
  }
}
@media (max-width: 767px) {
  :root {
    --spaceArea: calc(80px - var(--spacePart) - var(--spaceUnit));
  }
}
:root .section--three {
  --spaceArea: 0;
}
:root {
  --deskWidth: 920px;
  --deskSpace: 21px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root .section--one {
  --deskWidth: 732px;
}
:root .section--four {
  --deskWidth: 1108px;
}
@media (min-width: 768px) {
  :root {
    --deskSpace: 177px;
  }
  :root .navbar,
  :root .section--three,
  :root .section--four,
  :root .section--five,
  :root .section--footer {
    --deskSpace: 62px;
    --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
  }
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1279px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root .section--three {
  --spacePart: 0;
}
:root {
  --spaceUnit: 10px;
}
@media (max-width: 1279px) {
  :root {
    --spaceUnit: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 10px;
  }
}
:root .section--three {
  --spaceUnit: 0;
}
:root .unit--imageLeftRight {
  --spaceUnit: 30px;
}
:root {
  --spaceTotal: 20px;
}
@media (max-width: 1279px) {
  :root {
    --spaceTotal: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 20px;
  }
}
:root .section--four {
  --spaceTotal: 0;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #E92B22;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #dc1f16;
}
.button:active {
  background-color: #ad1811;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1280px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 > .item {
  margin: 0 30px;
}
div.sub1 > .item > .menu {
  position: relative;
  padding-top: 19px;
  padding-bottom: 23px;
  color: #000000;
  font-size: 14px;
  line-height: 1.28571429;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: 400;
}
div.sub1 > .item > .menu:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 10px;
  background-color: #E92B22;
  transition: all 0.3s;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus,
div.sub1 > .item > .menu.path {
  color: #E92B22;
  font-weight: 700;
}
div.sub1 > .item > .menu:hover:after,
div.sub1 > .item > .menu:focus:after,
div.sub1 > .item > .menu.path:after {
  width: 100%;
}
.mainNavi {
  justify-content: center;
}
.branding {
  left: 48px;
  top: 11px;
}
#naviServices {
  position: absolute;
  right: -94px;
  top: 19px;
  margin-right: 0;
}
#naviServices .meta {
  width: 20px;
  height: 20px;
}
#naviServices .meta.service_tickets {
  display: none;
}
.section--footer .logo {
  height: 47px;
}
.layout1 h1 {
  font-size: 180px;
  line-height: 0.94444444;
}
.layout1 h1:before {
  top: 85px;
  height: 180px;
}
.layout2 h1,
h2 {
  font-size: 34px;
  line-height: 1.11764706;
}
.layout2 .seam h2 {
  font-size: 40px;
  line-height: 0.875;
}
h4 {
  font-size: 55px;
  line-height: 0.90909091;
}
.layout3 h4 {
  font-size: 40px;
  line-height: 0.875;
}
.layout3 h4:before {
  top: 17.5px;
  height: max(50%, 26px);
}
.loud {
  font-size: 30px;
  line-height: 1.2;
}
.section--two .area .unit--form,
.section--five .area .unit--form {
  width: 60%;
}
.section--four .area .unit.unit--imageLeftRight,
.section--four .area .unit.unit--mapLeftRight {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: min(31.25vw, 600px);
}
.section--four .area .unit.unit--imageLeftRight .unit__background,
.section--four .area .unit.unit--mapLeftRight .unit__background,
.section--four .area .unit.unit--imageLeftRight .part.cb-googlemaps,
.section--four .area .unit.unit--mapLeftRight .part.cb-googlemaps {
  position: absolute;
  top: 50%;
  right: 59.38628159%;
  transform: translateY(-50%);
  width: 44.375vw;
}
.section--four .area .unit.unit--imageLeftRight .unit__content,
.section--four .area .unit.unit--mapLeftRight .unit__content,
.section--four .area .unit.unit--imageLeftRight .unit__foot,
.section--four .area .unit.unit--mapLeftRight .unit__foot {
  box-sizing: border-box;
  padding-left: calc(50% + 10px);
}
.section--four .area .unit.unit--imageLeftRight.unit.imageRightmapRight .unit__background,
.section--four .area .unit.unit--mapLeftRight.unit.imageRightmapRight .unit__background,
.section--four .area .unit.unit--imageLeftRight.unit.imageRight .unit__background,
.section--four .area .unit.unit--mapLeftRight.unit.imageRight .unit__background,
.section--four .area .unit.unit--imageLeftRight.unit.imageRightmapRight .part.cb-googlemaps,
.section--four .area .unit.unit--mapLeftRight.unit.imageRightmapRight .part.cb-googlemaps,
.section--four .area .unit.unit--imageLeftRight.unit.imageRight .part.cb-googlemaps,
.section--four .area .unit.unit--mapLeftRight.unit.imageRight .part.cb-googlemaps {
  right: unset;
  left: 59.38628159%;
}
.section--four .area .unit.unit--imageLeftRight.unit.imageRightmapRight .unit__content,
.section--four .area .unit.unit--mapLeftRight.unit.imageRightmapRight .unit__content,
.section--four .area .unit.unit--imageLeftRight.unit.imageRight .unit__content,
.section--four .area .unit.unit--mapLeftRight.unit.imageRight .unit__content,
.section--four .area .unit.unit--imageLeftRight.unit.imageRightmapRight .unit__foot,
.section--four .area .unit.unit--mapLeftRight.unit.imageRightmapRight .unit__foot,
.section--four .area .unit.unit--imageLeftRight.unit.imageRight .unit__foot,
.section--four .area .unit.unit--mapLeftRight.unit.imageRight .unit__foot {
  padding-left: 0;
  padding-right: calc(50% + 10px);
}
.unit.unit--unitSponsors .part.pict {
  padding: 20px;
}
/*# sourceMappingURL=./screen-large.css.map */